<template>
  <v-card>
    <v-card-title class="white--text primary">{{ campaign.name }}</v-card-title>
    <div class="px-4 py-4">
      <span class="display-1">Are you sure you want to archive this campaign?</span>
      <br/>
      <span class="caption">This will also turn off processing of campaign reporting</span>
    </div>
    <v-card-actions>
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          @click="archiveCampaign(!campaign.isArchived)"
          color="primary"
          class="mx-2"
        >
          Confirm
        </v-btn>
        <v-btn
          color="red"
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
  // Services
  import CampaignController from '@/services/controllers/Campaign'

  export default {
    props: {
      campaign: {
        type: Object,
        required: true
      },
    },

    methods: {
      // Purpose: Function to delete a campaign
      async archiveCampaign (archive) {
        // Archive the campaign using its ID
        const res = await CampaignController.archiveCampaign(this.campaign.id,archive).catch(err => {
          // Catch and display snackbar error message 
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })

        // Enter scope if the campaign was archive successfully
        if(res) {
          // Display snackbar success message
          this.$root.$emit('snackbarSuccess', 'Successfully ' + (archive ? 'archived' : 'unarchived') + ' campaign')

          // Redirect user to campaigns
          if(this.$route.name !== 'Campaigns') {
            this.$router.push({ name: 'Campaigns' })
          }

          // Run function in parent 
          this.$emit('archiveSuccessHandler')
        }
      },
    },
  }
</script>
